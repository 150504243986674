<template>
  <div class="container-fluid">
    <div class="d-flex justify-content-center align-items-center mb-3 mt-4">

      <div class="col-12 col-md-6">
        <div class="p-inputgroup">
          <InputText
            v-model="searchValue"
            placeholder="Digite o que você procura e pressione enter"
            @keyup.enter="pesquisar"
          />
          <Button
            icon="pi pi-search"
            class="p-button-primary"
            @click="pesquisar"
          />
        </div>
      </div>

    </div>
  </div>
    
  <div class="grid d-flex justify-content-center mb-5">

    <div
      class=" col-12 d-flex justify-content-center text-center mb-2"
      v-if="!isResearch"
    >
      <p><b>Facilite o seu dia a dia!</b><br /> De forma rápida e prática você pode utilizar os Serviços Online.<br />
      Clique sobre o Serviço desejado, ou pesquise o que você gostaria de fazer, e siga os passos e instruções para completar sua operação.
      </p>
    </div>

    <div class="col-12 d-flex justify-content-center mb-3" v-if="isResearch">
      <Button
        label="Voltar"
        icon="pi pi-angle-left"
        @click="voltar"          
        class="p-button-primary"
      />
    </div>

    <div 
      class="col-4 col-md-1 p-0"
      v-for="func in funcionalidadesList.filter(property => property.pagina != '/logout')"
      :key="func.id"
    >
      <router-link
        :key="func.id"
        :to="func.pagina !== null ? func.pagina  : '/'"
      >
        <div class="menuHome p-0" :title="func.descricaoFuncionalidade">
          <div class="cardBody cardSelected">
            <div class="cardTitle ">
              <img :src="caminhoImagem(func.idImagemFuncionalidade)" style="width: 25px; height: 25px;" />
            </div>
            <div class="cardContent">
              {{ func.nomeFuncionalidade }}
            </div>
          </div>
        </div>
      </router-link>
    </div>

  </div>

</template>
<script>
export default {

  data() {
    return {
      searchValue: null,
      funcionalidadesList: [],
    };
  },

  async mounted() {

    await this.$root.fetchFuncionalidades();
    
    
    await this.pesquisar();
  },

  computed: {
    idCliente() {
      return this.$store.getters.getIdCliente;
    },
    funcionalidades() {
      return JSON.parse(JSON.stringify([...this.$root.menuFuncionalidades]));
    },
    funcionalidadesCards() {
      return this.funcionalidades.filter( v => v.id !== 'home');
    },
    isResearch() {
      return this.funcionalidadesList.length < this.funcionalidadesCards.length;
    },
    serverExec() {
      return this.$store.getters.getServerExec;
    },

  },

  methods: {

    pesquisar() {
      if (this.searchValue !== null && this.searchValue.length > 0) {  
        this.funcionalidadesList = this.funcionalidadesCards.filter(v => 
          v.nomeFuncionalidade.toLowerCase().includes(this.searchValue) 
          || v.descricaoFuncionalidade.toLowerCase().includes(this.searchValue));
      } else {
        this.funcionalidadesList = this.funcionalidadesCards;
      }
    },

    voltar() {
      this.searchValue = null;
      this.pesquisar();
    },

    caminhoImagem(idImagem) {
      return `${this.serverExec}/acessoBase/acessoclientbase/uploadGgImagem?id=${idImagem}&mainSessionId=${this.$root.appToken}`;
    },

  },

};
</script>

<style>
a {
  text-decoration: none;
}

.menuHome .cardBody{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menuHome:hover .cardSelected {
  background-color: rgb(229, 231, 232);
  border-radius: 4px;
  color: #000000;
}

.menuHome .cardTitle {
  align-items: center;
  background-color: rgba(241, 243, 244, 1);
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  height: 48px;
  justify-content: center;
  width: 48px;
  margin-top: 15px;
}

.menuHome .cardContent {
  font-size: 0.9rem;
  padding: 8px 2px;
  color: black;

  font-weight: 400;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

</style>